import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { appDatabasePrimaryFunctions, base } from '../../base';
import '../../styles/css/AdminMain.css';

class GamesHistory extends Component {
    constructor(props) {
        super(props);
        var date = new Date(Date.now())
        var year = date.getFullYear();
        var month = ("0" + (date.getMonth() + 1)).slice(-2);
        var day = ("0" + date.getDate()).slice(-2);
        date = year + "-" + month + "-" + day
        this.state = {
          gamesList: [],
          currentGame: {},
          startDate: date,
          endDate: date,
          loading:true,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (evt) {
      this.setState({[evt.target.name]: evt.target.value});
    }

    searchGames(){
      var startDate = this.state.startDate;
      var endDate = this.state.endDate;
      var milliStartDate = Date.parse(startDate)
      var milliEndDate = Date.parse(endDate) + 86400000
      var vm = this;
      this.setState({loading:true})
      base.fetch(`gamesList`, {
        context: this,
        asArray: true,
        queries: {
          orderByChild: 'timeStamp',
          startAt: milliStartDate,
          endAt: milliEndDate
        },
        then(data){
          var currentGame = vm.state.currentGame;
          if(currentGame.timeStamp < milliEndDate && currentGame.timeStamp > milliStartDate){
            data.push(currentGame)
          }
          this.setState({
            gamesList: data,
            loading: false
          })
        }
      });
    }

    convertSecondsTimeStampToHumanTime(dateInSeconds){
      var tzoffset = (new Date()).getTimezoneOffset() * 60;
      var afterTimezoneChangeSeconds = dateInSeconds - tzoffset
      return this.toDateTime(afterTimezoneChangeSeconds).toDateString() + " " + this.toStandardTime(new Date(this.toDateTime(afterTimezoneChangeSeconds).getTime()).toString().split(" ")[4])
    }

    toDateTime(secs) {
      var t = new Date(1970, 0, 1); // Epoch
      t.setSeconds(secs);
      return t;
    }

    toStandardTime(value) {
      if (value !== null && value !== undefined){ //If value is passed in
        if(value.indexOf('AM') > -1 || value.indexOf('PM') > -1){ //If time is already in standard time then don't format.
          return value;
        }
        else {
          if(value.length == 8){ //If value is the expected length for military time then process to standard time.
            var hour = value.substring ( 0,2 ); //Extract hour
            var minutes = value.substring ( 3,5 ); //Extract minutes
            var identifier = 'AM'; //Initialize AM PM identifier
     
            if(hour == 12){ //If hour is 12 then should set AM PM identifier to PM
              identifier = 'PM';
            }
            if(hour == 0){ //If hour is 0 then set to 12 for standard time 12 AM
              hour=12;
            }
            if(hour > 12){ //If hour is greater than 12 then convert to standard 12 hour format and set the AM PM identifier to PM
              hour = hour - 12;
              identifier='PM';
            }
            return hour + ':' + minutes + ' ' + identifier; //Return the constructed standard time
          }
          else { //If value is not the expected length than just return the value as is
            return value;
          }
        }
      }
    }

    populateCSVEmails(){
      let game_ids = [];
      let gamesList = this.state.gamesList;
      let email_in_time_period = [];
      this.setState({loading:true})
      for(var game in gamesList){
        game_ids.push(gamesList[game].id)
      }
      const games_promises = game_ids.map(id => {
        return appDatabasePrimaryFunctions.ref().child('userGameHistory').orderByChild(id).equalTo(id).once('value', s => s)
      })
      Promise.all(games_promises)
      .then(email_lists => {
        var userIds = [];
        for(var list in email_lists){
          if(email_lists[list].val()){
            var keysArray = Object.keys(email_lists[list].val());
            for(var id in keysArray){
              var userId = keysArray[id]
              if(userIds.indexOf(userId) === -1){
                userIds.push(userId)
              }
            }
          }
        }
        const email_promises = userIds.map(id => {
          return appDatabasePrimaryFunctions.ref().child('users').child(id).child('email').once('value', s => s)
        })
        Promise.all(email_promises)
        .then(id_lists => {
          for(var list in id_lists){
            email_in_time_period.push(id_lists[list].val())
          }
          this.download_csv(email_in_time_period)
          this.setState({loading:false})
        })
        .catch(err => {
          console.log(err)
          this.setState({loading:false})
        })
      })
      .catch(err => {
        console.log(err)
        this.setState({loading:false})
      })
    }

    download_csv(data) {
      var csv = 'Email\n';
      data.forEach(function(row) {
              csv += row;
              csv += "\n";
      });
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      var date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv"
      hiddenElement.download = "players_during_timeperiod_" + date_got;
      hiddenElement.click();
    }

    componentDidMount(){
        this.setState({loading: false,})
    }

    render() {
      var gamesList = this.state.gamesList;
      var totalUsers = 0;
      var totalAnswers = 0;
      var vm = this;
      for(var game in gamesList){
        totalUsers += gamesList[game].users || 0
        totalAnswers += gamesList[game].answers || 0
      }
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}></div>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel" style = {{color:'black'}}>
              <div className="card">
                <div className="card-body">
                  <p className="admin-header-text" style={{marginBottom:0}}>Games History</p>
                  <p className="admin-subheader-text">Check out games over time</p>
                  <div className="form-inline">
                    <label htmlFor="startDate" style={{marginRight: 10}}>Start Date</label>
                    <input style={{marginRight:10}} id="startDate" name="startDate" type="date" className="form-control" value={this.state.startDate} onChange={this.handleChange}/>

                    <label htmlFor="endDate" style={{marginRight: 10}}>End Date</label>
                    <input style={{marginRight:10}} id="endDate" name="endDate" type="date" className="form-control" value={this.state.endDate} onChange={this.handleChange}/>
                    <button className="btn btn-primary" onClick={()=>this.searchGames()}>Find Games Between Dates</button>
                  </div>
                  <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                    <div className="card card-styles text-xs-center">
                        <div className="card-body">
                            <blockquote className="card-bodyquote">
                                <p>Date Range Players Who Logged In</p>
                                <footer className="value-text">{totalUsers}</footer>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center">
                        <div className="card-body">
                            <blockquote className="card-bodyquote">
                                <p>Date Range Answers</p>
                                <footer className="value-text">{totalAnswers}</footer>
                            </blockquote>
                        </div>
                    </div>
                  </div>
                  <button className="btn btn-primary" onClick={()=>this.populateCSVEmails()}>Download Time-Period Emails (Unique)</button>
                  <div style={{height:'10px', width:'100%'}}></div>
                  <h4>Games Breakdown</h4>
                  <ol>
                  {
                    gamesList.map(function(item,index){
                      var timeStamp;
                      if(item.isActive){
                        timeStamp = "Active Game"
                      } else {
                        timeStamp = vm.convertSecondsTimeStampToHumanTime(item.timeStamp/1000)
                      }
                      return(
                        <li key={index}>Game Name: {item.gameName}; Game Time: {timeStamp}
                          <span style={{marginLeft:"40px"}}>
                            <ul>
                              <li>Players: {item.users}</li>
                              <li>Answers: {item.answers}</li>
                            </ul>
                          </span>
                        </li>
                      )
                    })
                  }
                  </ol>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default GamesHistory
