import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { app } from '../../base';
import logoImage from '../../styles/images/sqwad-hand.png';
import logoWithTextImageBlack from '../../styles/images/sqwad_logo_black.svg';
import '../../styles/css/main.css';
import swal from 'sweetalert2';

class AdminLogin extends Component {
    constructor(props) {
        super(props);
        this.authWithEmailPassword = this.authWithEmailPassword.bind(this);
        this.state = {
          loading: true,
          redirect: false
        };
    }

    authWithEmailPassword(event) {
      event.preventDefault();
      const email = this.emailInput.value;
      const password = this.passwordInput.value;
      this.setState({loading:true});
      app.auth().fetchSignInMethodsForEmail(email)
      .then((providers) => {
        this.setState({loading:false});
        if (providers.indexOf("password") === -1) {
            // they used facebook
            this.loginForm.reset();
            swal({
                title: 'Uh Oh',
                text: "Looks like you used a different sign in method last time.",
                type: 'warning',
                confirmButtonText: 'Ok'
              })
          } else {
            // sign user in
            return app.auth().signInWithEmailAndPassword(email, password)
          }
        }, this)
        .then((user) => {
          if (user) {
            this.loginForm.reset();
            this.props.setCurrentUser(user);
            this.setState({
              redirect: true,
            })
          } else {
            swal({
                title: 'Wrong credentials',
                text: "Check your email and password and try again!",
                type: 'warning',
                confirmButtonText: 'Ok'
              })
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal({
              title: 'Uh oh!',
              text: error,
              type: 'error',
              confirmButtonText: 'Ok'
            })
        })
  }

    componentDidMount(){
        if (app.auth().currentUser) {
          this.setState({ redirect: true })
        }
        this.setState({
          loading: false
        })
    }

    render() {
      const teamLogo = logoImage;
      if (this.state.redirect === true || this.props.user) {
        return <Redirect to="/admin" />
      }
        return (
            <div className="auth-fluid">
              <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
              <div className="auth-fluid-form-box">
                <div className="align-items-center d-flex h-100">
                    <div className="card-body">

                        <div className="auth-brand text-center text-lg-left" style={{marginBottom:'50px'}}>
                            <img src={logoWithTextImageBlack} alt="" height="auto" width="200px"/>
                        </div>

                        <h4 className="mt-0" style={{fontWeight:'bolder', fontFamily:'Roboto'}}>Welcome</h4>
                        <p className="text-muted2 mb-4" style={{fontWeight:'bold', fontFamily:'Roboto'}}>Enter your email address and password to access account.</p>

                        <form onSubmit={(event) => { this.authWithEmailPassword(event) }} ref={(form) => { this.loginForm = form }} style={{fontWeight:'bold', fontFamily:'Roboto'}}>
                            <div className="form-group">
                                <label htmlFor="emailaddress">Email address</label>
                                <input className="form-control" type="email" name="email" ref={(input) => { this.emailInput = input }} placeholder="Enter your email"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input className="form-control" name="password" type="password" ref={(input) => { this.passwordInput = input }} placeholder="Enter your password"/>
                            </div>
                            <div className="form-group mb-0 text-center">
                                <button className="btn btn-primary btn-block" type="submit" style={{backgroundColor:"#ff1f3e", borderColor:"#ff1f3e", fontWeight:'bold', fontFamily:'Roboto'}}>Log In </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <div className="auth-fluid-right text-center">
                <div className="auth-user-testimonial">
                    <h2 className="mb-3" style={{fontWeight:'bolder', fontFamily:'Roboto'}}>Photo Scramble Tip Of The Month </h2>
                    <p className="lead" style={{fontWeight:'bold', fontFamily:'Roboto'}}>Plug the emails collected into Facebook, Instagram, & Snapchat ads for ticketing retargeting (5x-8x returns)
                    </p>
                    <img src={teamLogo} width="200px" alt=""/>
                </div>
            </div>
        </div>
        );
    }
}

export default AdminLogin
