import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';

class SetUpRulesAndRegs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rulesAndRegsText: "",
          rulesAndRegsLink: "",
            howToPlayLink: "",
          tenantRules: null,
          loading: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.rulesAndRegsLinkRef = base.bindToState(`tenantRules/rulesAndRegsLink`, {
            context: this,
            state: 'rulesAndRegsLink',
        });
        this.howToPlayLinkRef = base.bindToState(`tenantRules/howToPlayLink`, {
            context: this,
            state: 'howToPlayLink',
        });
        this.rulesAndRegsTextRef = base.bindToState(`tenantRules/rulesAndRegsText`, {
            context: this,
            state: 'rulesAndRegsText',
        });
        this.setState({
            loading: false
        })
    }

    componentWillUnmount() {
      base.removeBinding(this.rulesAndRegsTextRef);
      base.removeBinding(this.rulesAndRegsLinkRef);
      base.removeBinding(this.howToPlayLinkRef);
    }

    handleSubmit(event) {
      event.preventDefault();
      let rulesAndRegsText = this.state.rulesAndRegsText;
      let rulesAndRegsLink = this.state.rulesAndRegsLink;
      let howToPlayLink = this.state.howToPlayLink;
      // var updateRulesObject = {"rulesAndRegsText": rulesAndRegsText, "rulesAndRegsLink": rulesAndRegsLink}
      let updateRulesObject = {"rulesAndRegsText": rulesAndRegsText, "rulesAndRegsLink": rulesAndRegsLink, "howToPlayLink": howToPlayLink}
      this.setState({loading:true})
      let vm = this;
      base.post("tenantRules/", {
        data: updateRulesObject,
        then(err){
          vm.setState({loading:false})
          if(!err){
            swal({
                title: 'Rules and Regs Updated!',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
              })
          } else {
            swal({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try logging out and logging back in',
                type: 'error',
                confirmButtonText: 'Ok'
              })
            console.log(err)
          }
        }
      })
    }

    handleChange (evt) {
      this.setState({ [evt.target.name]: evt.target.value });
    }

    isEmpty(obj) {
    for(let prop in obj) {
      if(obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

    render() {
      let rulesAndRegsText = this.state.rulesAndRegsText;
      let rulesAndRegsLink = this.state.rulesAndRegsLink;
        let howToPlayLink = this.state.howToPlayLink;
      if(this.isEmpty(this.state.rulesAndRegsText)){
        rulesAndRegsText = ""
      }
      if(this.isEmpty(rulesAndRegsLink)){
        rulesAndRegsLink = ""
      }
        if(this.isEmpty(howToPlayLink)){
            howToPlayLink = ""
        }
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="container-out" style={{width:'45%', float:'left'}}>
                <div className="admin-form-box">
                  <form onSubmit={this.handleSubmit} id="create-game-form">
                    <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                    <div className="form-group">
                        <label htmlFor="rulesAndRegsText">Rules And Regs Text</label>
                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This text will appear AFTER the mandatory confirm rules and regs button on the login form<br/><strong>Example:</strong> Welcome to Predictions! To play, please confirm that you agree with the rules and regs</p>
                        <input type="text" id="rulesAndRegsText" name="rulesAndRegsText" className="form-control" value={rulesAndRegsText} onChange={this.handleChange}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="rulesAndRegsLink">Rules And Regs Link</label>
                      <p className="text-muted2" style={{fontSize:'10px'}}>This is where your rules and regulations are hosted</p>
                      <input id="rulesAndRegsLink" name="rulesAndRegsLink" type="url" className="form-control" value={rulesAndRegsLink} onChange={this.handleChange} placeholder="https://ourrules.com"/>
                    </div>
                      <div className="form-group">
                          <label htmlFor="howToPlayLink">How To Play Link</label>
                          <p className="text-muted2" style={{fontSize:'10px'}}>This is where you can teach fans how to play</p>
                          <input id="howToPlayLink" name="howToPlayLink" type="url" className="form-control" value={howToPlayLink} onChange={this.handleChange} placeholder="https://ourgameplay.com"/>
                      </div>
                  </form>
                </div>
              </div>
              <div className="container-out" style={{width:'45%', float:'right', marginRight:'20px'}}>
                <div className="admin-form-box" style={{border:'1px solid black', backgroundColor:'white'}}>
                  <p style={{fontSize:'20px', fontWeight:'bold'}}>Why do I need rules & regulations?</p>
                  <p className="text-muted2">While each state, country, and province is different, you will want to make sure you are compliant with all contest rules and laws.  It is your responsibility to update, maintain, & host your rules and regulations tab.</p>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default SetUpRulesAndRegs;
