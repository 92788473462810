import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from "sweetalert2";
const google = window.google;
let geocoder = new google.maps.Geocoder();

class SetUpLoginVariables extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
    }

    componentDidMount(){
      this.tenantVariablesRef = base.syncState(`tenantVariables`, {
        context: this,
        state: 'tenantVariables',
          then(){
            const tenantVariables = this.state.tenantVariables;
            if(tenantVariables){
                this.setState({
                    acceptableDistance: tenantVariables.acceptableDistance,
                    locationPermissionsBody: tenantVariables.locationPermissionsBody,
                    locationPermissionsHeader: tenantVariables.locationPermissionsHeader,
                    formattedAddress: tenantVariables.formattedAddress,
                })
            }
          }
      });
      this.setState({
        loading:false
      })
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    handleChange (evt) {
        this.setState({ [evt.target.name]:  evt.target.value});
    }

    handleTextChange(evt){
        const tenantVariablesCopy = this.state.tenantVariables;
        tenantVariablesCopy[evt.target.name] = evt.target.value
        this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    selectOption(button){
      const tenantVariablesCopy = this.state.tenantVariables;
      tenantVariablesCopy[button] = !tenantVariablesCopy[button]
      this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    updateDistance(){
        const tenantVariables = this.state.tenantVariables;
        if(!this.state.acceptableDistance || !this.state.formattedAddress){
            swal({
                title: 'Hold on!',
                text: "Please make sure all the form fields are filled out",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        if(this.state.acceptableDistance <= 0){
            swal({
                title: 'Hold on!',
                text: "Please make sure distance is greater than 0",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        this.setState({loading:true})
        const vm = this;
        geocoder.geocode({'address':this.state.formattedAddress}, function(results, status) {
            if (status === 'OK') {
                if(results.length === 1){
                    const tenantVariablesCopy = tenantVariables;
                    tenantVariablesCopy["formattedAddress"] = vm.state.formattedAddress;
                    tenantVariablesCopy["acceptableDistance"] = vm.state.acceptableDistance;
                    tenantVariablesCopy["longitude"] = results[0].geometry.location.lng();
                    tenantVariablesCopy["latitude"] = results[0].geometry.location.lat();
                    tenantVariablesCopy["locationPermissionsBody"] = vm.state.locationPermissionsBody || "";
                    tenantVariablesCopy["locationPermissionsHeader"] = vm.state.locationPermissionsHeader || "";
                    vm.setState({
                        tenantVariables:  tenantVariablesCopy,
                        loading: false
                    });
                    swal({
                        title: 'Updates Successful',
                        type: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: true,
                        timer: 10000
                    })
                } else {
                    vm.setState({loading:false})
                    swal({
                        title: 'Hold on!',
                        text: "Too many locations have that address! Add more detail to get only 1 address",
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                }
            } else {
                vm.setState({loading:false})
                swal({
                    title: 'Hold on!',
                    text: 'Finding address was not successful because ' + status,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    render() {
        const variables = this.state.tenantVariables || {}
        const collectName = variables.collectName;
        const mandatoryTermsAndConditions = variables.mandatoryTermsAndConditions;
        const collectZipCode = variables.collectZipCode;
        const collectOptIn = variables.collectOptIn;
        const allowAnonymousLogin = variables.allowAnonymousLogin;
        const doNotCollectEmail = variables.doNotCollectEmail;
        const collectDistance = variables.collectDistance;
        const acceptableDistance = parseFloat(this.state.acceptableDistance);
        let formattedAddress = this.state.formattedAddress;
        let locationPermissionsBody = this.state.locationPermissionsBody;
        let locationPermissionsHeader = this.state.locationPermissionsHeader;
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="container-out container-left-rules"  style={{float:"left"}}>
                <div className="admin-form-box">
                    <div className="form-group">
                        <label htmlFor="doNotCollectEmail">DO NOT Collect Email</label>
                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to remove all email collection from the game</p>
                        <input type="checkbox" checked={doNotCollectEmail} id="doNotCollectEmail" name="doNotCollectEmail" onClick={()=> this.selectOption("doNotCollectEmail")}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="rulesAndRegsText">Collect Name Of Fan</label>
                      <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a MANDATORY name field to the login flow</p>
                      <input type="checkbox" checked={collectName} id="collectName" name="collectName" onClick={()=> this.selectOption("collectName")}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="collectOptIn">Collect ZipCode</label>
                      <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a MANDATORY zip code field to the login flow</p>
                      <input type="checkbox" checked={collectZipCode} id="collectZipCode" name="collectZipCode" onClick={()=> this.selectOption("collectZipCode")}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="mandatoryTermsAndConditions">Turn on Mandatory Terms And Conditions</label>
                      <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a MANDATORY confirm rules and regs checkbox<br/>Rules and Regs Text and Link come from the <a href="/setuprulesandregs"><u>Rules and Regs Tab</u></a></p>
                      <input type="checkbox" checked={mandatoryTermsAndConditions} id="mandatoryTermsAndConditions" name="mandatoryTermsAndConditions" onClick={()=> this.selectOption("mandatoryTermsAndConditions")}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="collectOptIn">Turn on Optional Opt-In</label>
                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a optional field to the login flow, enter the text below</p>
                        <input type="checkbox" checked={collectOptIn} id="collectOptIn" name="collectOptIn" onClick={()=> this.selectOption("collectOptIn")}/>
                    </div>
                    <div className="form-group" style={{display: collectOptIn? "":"none"}}>
                        <label htmlFor="promotionText">Opt-In Text</label>
                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Text That Shows Next To Opt-In Check Box</p>
                        <textarea id="promotionText" name="promotionText" className="form-control" value={variables.promotionText} onChange={this.handleTextChange}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="collectDistance">Turn On/Off Geolocation</label>
                        <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box to add MANDATORY verification of distance from stadium</p>
                        <input type="checkbox" checked={collectDistance} id="collectDistance" name="collectDistance" onClick={()=> this.selectOption("collectDistance")}/>
                        <span style={{display: collectDistance ? 'block' : 'none'}}>
                            <div>
                              <label htmlFor="formattedAddress" style={{marginTop: 5, marginBottom: 0}}>Address</label>
                              <br/>
                              <input className="form-control" type="text" id="formattedAddress" name="formattedAddress" value={formattedAddress} onChange={this.handleChange} placeholder="Address"/>
                              <br/>
                              <label htmlFor="locationPermissionsHeader" style={{marginTop: 5, marginBottom: 0}}>Requesting Location Permissions Header</label>
                              <br/>
                              <input className="form-control" type="text" id="locationPermissionsHeader" name="locationPermissionsHeader" value={locationPermissionsHeader} onChange={this.handleChange} placeholder="Location Permissions Header"/>
                              <br/>
                              <label htmlFor="locationPermissionsBody" style={{marginTop: 5, marginBottom: 0}}>Requesting Location Permissions Body</label>
                              <br/>
                              <textarea className="form-control" id="locationPermissionsBody" name="locationPermissionsBody" value={locationPermissionsBody} onChange={this.handleChange} placeholder="Location Permissions Body"/>
                              <br/>
                              <label htmlFor="acceptableDistance" style={{marginTop: 5, marginBottom: 0}}>Distance (Miles)</label>
                              <br/>
                              <input className="form-control" type="number" id="acceptableDistance" step="0.1" name="acceptableDistance" min="0" value={acceptableDistance} onChange={this.handleChange} placeholder=""/>
                            </div>
                            <button className="btn btn-primary btn-lg update-button" style={{marginTop: 5}} onClick={() => this.updateDistance()}><span className="fa fa-arrow-circle-o-up"/> Update Distance Variables</button>
                        </span>
                    </div>
                </div>
              </div>
              <div className="container-out mobile-hide" style={{width:'45%', float:'right', marginRight:'20px'}}>
                <div className="admin-form-box" style={{border:'1px solid black', backgroundColor:'white'}}>
                  <p style={{fontSize:'20px', fontWeight:'bold'}}>What Data Should I Gather?</p>
                  <p className="text-muted2">Think carefully about what you want to gather from your fan base.  The more you ask for, the fewer people will be willing to go through the whole logging in process.
                  Balance that with useful parameters that you can use to help your fans get more value from your experience.</p>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default SetUpLoginVariables;
