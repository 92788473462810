import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import { base, appDatabasePrimaryFunctions } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import imageToSlices from 'image-to-slices';
import { Accordion, AccordionItem } from 'react-sanfona';
import Dropzone from "react-dropzone";
import UploadImage from "../utils/UploadImage";

class SetUpGame extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            ticketList: [],
            tenantVariables: {},
            emailsSent: {},
            activeSquareCodes: {},
            emailVariables: {},
            tenantRules: {},
            id_key: "",
            addedItems:[],
            currentGame: null,
            modal: false,
            userGameHistory: [],
            users: [],
            gameName: "",
            loading: true,
            wizardLocation: 'first',
            endTime: null,
            startTime: null,
            deleteAddedItems: false,
            splitImages: [],
            scrambleImage: {},
            userAnswers: [],
            sentEmails: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    getCurrentTime(datePassedIn){
        if(!datePassedIn){
            datePassedIn = new Date();
        }
        return `${datePassedIn.getFullYear()}-${`${datePassedIn.getMonth() + 1}`.padStart(2, 0)}-${`${datePassedIn.getDate()}`.padStart(2, 0)}T${`${datePassedIn.getHours()}`.padStart(2, 0)}:${`${datePassedIn.getMinutes()}`.padStart(2, 0)}`;
    }

    componentDidMount() {
        this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
        });
        this.idKeyRef = base.fetch(`id_key`, {
            context: this,
            then(data){
              this.setState({
                id_key: data
              })
            }
        });
        this.emailVariablesRef = base.bindToState(`emailVariables`, {
            context: this,
            state: 'emailVariables',
        });
        this.tenantRulesRef = base.bindToState(`tenantRules`, {
            context: this,
            state: 'tenantRules',
        });
        this.rewardsListRef = base.syncState(`rewardsList`, {
            context: this,
            state: 'ticketList',
            asArray: true
        });
        this.usersRef = base.bindToState('users', {
            context: this,
            state: 'users',
        });
        this.userAnswersRef = base.syncState('userAnswers', {
            context: this,
            state: 'userAnswers',
            asArray: true
        });
        this.emailsSentRef = base.syncState('emailsSent', {
            context: this,
            state: 'emailsSent'
        });
        this.currentGameRef = base.syncState(`currentGame`, {
            context: this,
            state: 'currentGame',
            then(err){
              if(!err){
                this.setGameToWatch(this.state.currentGame.id);
              }
              this.setState({loading:false})
            }
        });
        this.userGameHistoryRef = base.bindToState('userGameHistory', {
            context: this,
            state: 'userGameHistory',
            asArray: true
        })
    }

    downloadUsers(){
        const userAnswers = this.state.userAnswers || [];
        let players = this.state.userGameHistory || [];
        const users = this.state.users || {};
        const currentUsersState = this.getAndSortUsers(players, userAnswers) || [];
        let csv = 'Email,Name,Zip Code,Opt-In,High Score,Reward Sent,Code\n';
        currentUsersState.forEach(function(row) {
        csv += row.email;
        csv+= ",";
        csv+= (users[row.uid].name || "");
        csv+= ",";
        csv+= (users[row.uid].zipCode || "");
        csv+= ",";
        csv+= (users[row.uid].optIn || "");
        csv+= ",";
        csv+= row.highScore;
        csv+= ",";
        csv+= (row.rewardSent || "NONE");
        csv+= ",";
        csv+= (row.code || "-");
        csv += "\n";
        });
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv";
        hiddenElement.download = "current_users_" + date_got;
        hiddenElement.click();
    }

    setGameToWatch(id){
      if(this.userGameHistoryRef){
        base.removeBinding(this.userGameHistoryRef);
      }
      if(id){
        this.userGameHistoryRef = base.bindToState('userGameHistory',{
          context: this,
          state: 'userGameHistory',
          asArray: true,
          queries: {
            orderByChild: id,
            equalTo: id,
          }
        })
      }
    }

    componentWillUnmount() {
        if(this.userGameHistoryRef){
            base.removeBinding(this.userGameHistoryRef);
        }
        base.removeBinding(this.tenantVariablesRef);
        base.removeBinding(this.rewardsListRef);
        base.removeBinding(this.currentGameRef);
        base.removeBinding(this.usersRef);
        base.removeBinding(this.tenantRulesRef);
        base.removeBinding(this.emailVariablesRef);
        base.removeBinding(this.idKeyRef);
        base.removeBinding(this.userAnswersRef);
        base.removeBinding(this.emailsSentRef);
    }

    findItem(itemId, list){
      for(const itemIndex in list){
        let item = list[itemIndex];
        if(item.key === itemId){
          return Object.assign({}, item);
        }
      }
    }

    detectHowManyCodesEntered(codesArray){
        const splitOnLineBreaks = codesArray.split("\n");
        let splitOnWhiteSpace = codesArray.split(/\s+/);
        let splitOnCommas = codesArray.split(",");
        let splitArray = splitOnLineBreaks;
        if(splitOnWhiteSpace.length === splitOnCommas.length){
            splitOnWhiteSpace = codesArray.replace(/,/g, '').split(/\s+/);
            splitOnCommas = codesArray.replace(/\s/g,'').split(",");
        }
        if(splitArray.length < splitOnWhiteSpace.length){
            splitArray = splitOnWhiteSpace
        }
        if(splitArray.length < splitOnCommas.length){
            splitArray = splitOnCommas
        }
        return splitArray
    }

    async createGame(){
        let createGameObject = {};
        const userAnswers = this.state.usersAnswers || [];
        const gameId = appDatabasePrimaryFunctions.ref().push().key;
        let startTime = this.state.startTime;
        let endTime = this.state.endTime;
        if(!startTime || !endTime){
            swal({
                title: "Hold On!",
                text: 'The game needs a start time and end time!',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        startTime = new Date(startTime).getTime()/1000;
        endTime = new Date(endTime).getTime()/1000;
        if(startTime >= endTime){
            swal({
                title: "Hold On!",
                text: 'The game start time cannot be equal to or greater than the game end time!',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        if(startTime <= Date.now()/1000){
            swal({
                title: "Hold On!",
                text: 'The game start time cannot be before now!',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        let gameName = this.state.gameName.trim();
        let {main_prize, main_prize_codes, main_prize_amount, other_prize, splitImages, main_prize_codesArray} = this.state;
        main_prize = this.findItem(main_prize, this.state.ticketList);
        other_prize = this.findItem(other_prize, this.state.ticketList);
        if(!splitImages){
            swal({
                title: "Hold On!",
                text: 'The game needs an image to scramble',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        if(!gameName){
        swal({
            title: "Hold On!",
            text: 'The game needs a name!',
            type: 'warning',
            confirmButtonText: 'Ok'
          });
        return
        }
        if(main_prize && main_prize.rewardName && (!main_prize_amount || 1 > main_prize_amount)){
            swal({
                title: "Hold On!",
                text: "You have a main prize selected but no amount added with it.  It needs some amount other wise make it an Other Winner Prize",
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return;
        }
        if(main_prize_codes){
            if(main_prize_codesArray.trim() === ""){
                swal({
                    title: 'Hold on!',
                    text: 'Must enter codes or uncheck codes box!',
                    type: 'warning',
                    confirmButtonText: 'Ok'
                });
                return;
            } else {
                const totalCodes = this.detectHowManyCodesEntered(main_prize_codesArray);
                if(totalCodes.length !== parseInt(main_prize_amount,10)){
                    swal({
                        title: 'Codes not equal!',
                        text: "# of codes must equal number of rewards created. In this case: " +  totalCodes.length + " does not equal " + main_prize_amount,
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    });
                    return;
                }
                main_prize.codes = totalCodes;
            }
        } else if(main_prize){
            main_prize.codes = null
        }
        if(main_prize){
            main_prize.amount = main_prize_amount;
            createGameObject.main_prize = main_prize;
        } else {
            main_prize = false
        }
        if(other_prize){
            createGameObject.other_prize = other_prize;
        } else {
            other_prize = false
        }
        this.setState({loading:true})
        createGameObject.gameName =  gameName;
        createGameObject.active =  false;
        createGameObject.ended =  false;
        createGameObject.main_prize = main_prize;
        createGameObject.other_prize = other_prize;
        createGameObject.scheduleInfo = {};
        createGameObject.scheduleInfo['status'] = 'scheduled';
        createGameObject.scheduleInfo['performAt'] = startTime;
        createGameObject.scheduleInfo['endAt'] = endTime;
        createGameObject.images = splitImages;
        const responseToFileUpload = await this.UploadImage.upload_file(this.state.scrambleImage.fileToUpload);
        if(!responseToFileUpload || !responseToFileUpload.imageUrl){
            swal({
                title: "Hold On!",
                text: "There was a problem uploading your photo, make sure it is the correct file type and not over 5mb",
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            this.setState({
                loading:false
            })
            return;
        }
        createGameObject.correctImage = responseToFileUpload.imageUrl;
        let currentGame = this.state.currentGame;
        currentGame['users'] = this.state.userGameHistory.length;
        currentGame['answers'] = userAnswers.length;
        const vm = this;
        if(currentGame.gameName){
            base.push('gamesList', {
                data: currentGame,
                then(err) {
                    if (!err) {
                        createGameObject.id = gameId;
                        createGameObject.timeStamp = Date.now();
                        vm.setState({
                            currentGame: createGameObject,
                            userAnswers: [],
                            modal: false,
                            loading: false,
                            emailsSent: null
                        }, () => {
                            vm.setGameToWatch(gameId);
                            base.post("currentRewardsStatus/main_prize", {
                                data: 0,
                                then(err){
                                    console.log(err)
                                }
                            })
                        })
                    } else {
                        alert("Error Occurred: " + err);
                        vm.setState({
                            loading: false
                        })
                    }
                }
            })
        } else {
            vm.setState({
                currentGame: createGameObject,
                modal: false,
                loading: false
            }, () => {
                vm.setGameToWatch(gameId);
            })
        }
        base.post("currentRewardsStatus/main_prize", {
            data: 0,
            then(err){
                console.log(err)
            }
        })
    }

    stopGame(){
      let currentGame = this.state.currentGame;
      currentGame.active = false;
      this.setState({
        currentGame: currentGame
      })
      swal({
        title: 'Game Stopped',
        type: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: true,
        timer: 10000
      })
    }

    startGame() {
        let currentGame = this.state.currentGame;
        this.setState({loading:true})
        currentGame.timeStamp = Date.now()
        currentGame.active = true;
        this.setState({
            currentGame: currentGame,
            loading: false
        });
        swal({
            title: 'Game Started',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
        })
    }

    getAndSortUsers(totalUsers, userScores){
        const sortedUsers = [];
        const notPlayedUsers = [];
        const userScoresObject = {};
        const emailsSent = this.state.emailsSent || {};
        for(const userScoresIndex in userScores){
            const currentUserScore = userScores[userScoresIndex];
            userScoresObject[currentUserScore.uid] = currentUserScore.numberOfMoves;
        }
        for(const totalUsersIndex in totalUsers){
            const user = {};
            let uid = totalUsers[totalUsersIndex].key;
            let rewardSent = null;
            let code = null;
            if(emailsSent[uid]){
                rewardSent = emailsSent[uid].rewardName;
                code = emailsSent[uid].code;
            }
            user['email'] = atob(uid);
            user['uid'] = uid;
            user['rewardSent'] = rewardSent
            user['code'] = code
            user['highScore'] = userScoresObject[uid] || 0;
            if(user['highScore'] === 0){
                notPlayedUsers.push(user)
            } else {
                sortedUsers.push(user)
            }
        }
        sortedUsers.sort(function(a,b){
            return a.highScore - b.highScore
        });
        return sortedUsers.concat(notPlayedUsers)
    }

    handleChange (evt) {
      let target = evt.target;
      let value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ [evt.target.name]: value });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        wizardLocation: "first",
      });
    }

    resetGame(){
      swal({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will erase all information gathered during this game! Only do this if no one has played yet.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES I WANT TO RESET THE GAME',
        }).then((result)=>{
          if(result.value){
            let currentGameState = this.state.currentGame;
            currentGameState.active = false;
            let history = this.state.userGameHistory;
            for(let historyRef in history){
              let refInstance = history[historyRef];
              base.post(`userGameHistory/${refInstance.key}/${currentGameState.id}`, {
                data: null,
                then(err){
                  if(!err){
                    console.log("success")
                  }
                }
              });
            }
              base.post("currentRewardsStatus/main_prize", {
                  data: 0,
                  then(err){
                      console.log(err)
                  }
              })
            this.setState({
              currentGame: currentGameState,
                active: false,
                userAnswers: [],
                emailsSent: null
            })
          }
        })
    }

    navButtonClicked(direction){
      const currentLocation = this.state.wizardLocation;
      if(direction === 'prev' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'first'
        });
        this.toggleWizardLocation('first', 'second', 'third', 'fourth')
      } else if(direction === 'prev' && currentLocation === 'third'){
        this.setState({
          wizardLocation: 'second'
        });
        this.toggleWizardLocation('second', 'first', 'third', 'fourth')
      } else if(direction === 'next' && currentLocation === 'first'){
        this.setState({
          wizardLocation: 'second',
        });
        this.toggleWizardLocation('second', 'first', 'third', 'fourth')
      } else if(direction === 'next' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'third',
        });
        this.toggleWizardLocation('third', 'first', 'second', 'fourth')
      } else if(direction === 'next' && currentLocation === 'third'){
          this.setState({
              wizardLocation: 'fourth',
          });
          this.toggleWizardLocation('fourth', 'first', 'second', 'third')
      } else if(direction === 'prev' && currentLocation === 'fourth'){
          this.setState({
              wizardLocation: 'third',
          });
          this.toggleWizardLocation('third', 'first', 'second', 'fourth')
      } else {
        this.setState({
          wizardLocation: 'first'
        });
        this.toggleWizardLocation('first', 'second', 'third', 'fourth')
      }
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2, otherTab3){
      this.setState({
        wizardLocation: tabClicked
      });
      document.getElementById(tabClicked).classList.add('active');
      document.getElementById(tabClicked).classList.add('show');
      document.getElementById(otherTab1).classList.remove('active');
      document.getElementById(otherTab1).classList.remove('show');
      document.getElementById(otherTab2).classList.remove('active');
      document.getElementById(otherTab2).classList.remove('show');
      document.getElementById(otherTab3).classList.remove('active');
      document.getElementById(otherTab3).classList.remove('show');
      document.getElementById(tabClicked+'1').classList.add('active');
      document.getElementById(tabClicked+'1').classList.add('show');
      document.getElementById(otherTab1+'1').classList.remove('active');
      document.getElementById(otherTab1+'1').classList.remove('show');
      document.getElementById(otherTab2+'1').classList.remove('active');
      document.getElementById(otherTab2+'1').classList.remove('show');
      document.getElementById(otherTab3+'1').classList.remove('active');
      document.getElementById(otherTab3+'1').classList.remove('show');
    }

    switchStatsPrizes(){
      if(this.state.showStats){
        document.getElementById('showPrizes').classList.remove('active');
        document.getElementById('showStats').classList.add('active');
        this.setState({
          showStats:false,
        })
      }else{
        document.getElementById('showPrizes').classList.add('active');
        document.getElementById('showStats').classList.remove('active');
        this.setState({
          showStats:true,
        })
      }
    }

    onDrop(files, rejected, myArgument) {
        if(rejected.length > 0){
            swal({
                title: 'Image cannot be uploaded',
                text: 'Make sure the image is less than 2mbs and it is an accepted file type',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        const fileToUpload = files[0];
        const vm = this;
        this.setState({loading: false}, () => {
            const lineXArray = [200, 400];
            const lineYArray = [200, 400];
            imageToSlices(URL.createObjectURL(fileToUpload), lineXArray, lineYArray, {
                saveToDataUrl: true
            }, function(dataUrlList){
                vm.setState({
                    [myArgument]: {
                        fileToUpload,
                     preview: URL.createObjectURL(fileToUpload)
                    },
                    splitImages: dataUrlList,
                    loading: false
                })
            })
        })
    }

    render() {
        const scrambleImage = this.state.scrambleImage;
        const splitImages = this.state.splitImages;
        const userAnswers = this.state.userAnswers || [];
      let selectedGame = this.state.currentGame || null;
      const rewardsList = [];
      let gameName = null;
      let players = this.state.userGameHistory || [];
      const currentUsersState = this.getAndSortUsers(players, userAnswers) || [];
      let players_length = players.length;
      let startTime = null;
      let endTime = null;
      if(selectedGame && selectedGame.gameName){
          if(selectedGame.main_prize){
              rewardsList.push(selectedGame.main_prize);
          }
          if(selectedGame.other_prize){
              const otherPrize = selectedGame.other_prize;
              otherPrize['amount'] = "Unlimited";
              rewardsList.push(otherPrize)
          }
        if(selectedGame.scheduleInfo){
            startTime = new Date(selectedGame.scheduleInfo.performAt*1000).toLocaleDateString() + " " + new Date(selectedGame.scheduleInfo.performAt*1000).toLocaleTimeString()
            endTime = new Date(selectedGame.scheduleInfo.endAt*1000).toLocaleDateString() + " " + new Date(selectedGame.scheduleInfo.endAt*1000).toLocaleTimeString()
        }
        gameName = selectedGame.gameName;
      } else {
        selectedGame = null;
      }
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
          <div className="admin-main-panel">
            <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
              <div className="row">
                <div className="col-md-3">
                  <div className="card" style={{backgroundColor:'#00c78c', width:'100%', textAlign: 'center', height:'50px', display: selectedGame && selectedGame.active ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game Live</p>
                  </div>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: selectedGame && !selectedGame.active ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game Not Live</p>
                  </div>
                </div>
                <div className="col-md-3">
                    <button onClick={() => this.stopGame()} className="btn btn-primary btn-lg end-game-button" style={{display: selectedGame && selectedGame.active ? '' : 'none', float:'left', height:'52px' }}>Stop Game</button>
                    <button onClick={() => this.startGame()} className="btn btn-primary btn-lg start-game-button" style={{display: selectedGame && !selectedGame.active ? '' : 'none', float:'left', height:'52px' }}>Start Game</button>
                </div>
                <div className="col-md-6" style={{textAlign:'right'}}>
                  <a className="btn btn-outline-danger btn-sm" onClick={() => this.resetGame()} style={{display: selectedGame ? '' : 'none', marginTop:'7px', float:'right'}}>Reset Game</a>
                  <button onClick={() => this.toggle()} className="btn btn-primary btn-lg create-game-button" width="50px" style={{float:'right', marginRight:'5px'}}>New Game</button>
                </div>
              </div>
            </div>
            <div style={{display: !selectedGame ? 'none': 'block'}}>
                <div className="row justify-content-center">
                    <h3 style={{color:'black', margin:'15px'}}>Game Set Up -- {gameName}</h3>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-5">
                        <p style={{color:"black", fontSize:15, fontWeight:'bold', margin:0}}>Starts At:</p>
                        <p style={{color:"black",border:"solid 1px black",backgroundColor: '#fff',fontSize:20,padding:5, display:'inline-flex'}}>{startTime}</p>
                    </div>
                    <div className="col-md-5">
                        <p style={{color:"black", fontSize:15, fontWeight:'bold', margin:0}}>Ends At:</p>
                        <p style={{color:"black", backgroundColor: '#fff', border:"solid 1px black", fontSize:20,padding:5, display:'inline-flex'}}>{endTime}</p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-5" style={{border: "solid 1px black", margin: 5}}>
                        <h4 style={{color:'black'}}>Rewards</h4>
                        {
                            rewardsList.map(function(item, index){
                                return (
                                    <div key={index} style={{textAlign:'left', color:'black'}}>
                                        <p>Name: {item.rewardName}</p>
                                        <p>Amount: {item.amount}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/*<div className="col-md-5" style={{border: "solid 1px black", margin: 5}}>*/}
                    {/*    <h4 style={{color:'black'}}>Partners Links</h4>*/}
                    {/*    {*/}
                    {/*        partnersList.map(function(item, index){*/}
                    {/*            if(partnerIds.indexOf(item.id) !== -1){*/}
                    {/*                return (*/}
                    {/*                    <div key={index} style={{textAlign:'left', color:'black'}}>*/}
                    {/*                        <p>Link: <a href={"/partners/"+item.id} target="_blank">{item.name}</a></p>*/}
                    {/*                    </div>*/}
                    {/*                )*/}
                    {/*            }*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*</div>*/}
                </div>
            </div>
            <div style={{display: !selectedGame ? 'none': 'block'}}>
              <div className="card-body">
                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes()}>
                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showStats" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Stats</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes()}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showPrizes" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Participants</span>
                        </a>
                    </li>
                </ul>
                <div style={{display: this.state.showStats ? 'block' : 'none'}}>
                    <div className="export-button-styles btn btn-primary btn-lg download-button" onClick={()=>this.downloadUsers()}>
                        <span className="fa fa-arrow-circle-down"/> Download Participants
                    </div>
                    <div style={{height:'10px', width:'100%'}}/>
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                        <th>Email</th>
                        <th>High Score</th>
                        <th>Reward Sent</th>
                        <th>Code</th>
                      </tr>
                      {
                        currentUsersState.map(function(item,i){
                            return <tr key={i}>
                              <td style={{fontFamily:'Open Sans'}}>{item.email}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.highScore}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.rewardSent || "NONE"}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.code || "NONE"}</td>
                            </tr>
                        }, this)
                      }
                    </tbody>
                  </table>
                </div>
                <div style={{display: !this.state.showStats ? 'block' : 'none'}}>
                  <div className="export-button-styles btn btn-primary btn-lg download-button" onClick={()=>this.downloadUsers()}>
                    <span className="fa fa-arrow-circle-down"/> Download Participants
                  </div>
                  <div className="admin-grid-container three-columns" style={{marginTop:20}}>
                    <div className="card card-styles text-xs-center">
                        <div className="card-body">
                            <blockquote className="card-bodyquote">
                                <p>Current Game Players</p>
                                <footer className="value-text">{players_length}</footer>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center">
                        <div className="card-body">
                            <blockquote className="card-bodyquote">
                                <p>Fans Solved Puzzle</p>
                                <footer className="value-text">{userAnswers.length}</footer>
                            </blockquote>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3"> Create Game</h4>
                  <div id="rootwizard">
                    <ul className="nav nav-pills bg-dark-light nav-justified form-wizard-header mb-3">
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('first', 'second', 'third', 'fourth')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">
                                <span className="fa fa-pencil-square-o"/>
                                <span className="d-none d-sm-inline"> The Basics</span>
                            </a>
                        </li>

                        <li className="nav-item" onClick={() => this.toggleWizardLocation('second', 'first', 'third', 'fourth')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">
                                <span className="fa fa-square"/>
                                <span className="d-none d-sm-inline"> Upload Photo</span>
                            </a>
                        </li>

                        <li className="nav-item" onClick={() => this.toggleWizardLocation('third', 'first', 'second', 'fourth')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">
                                <span className="fa fa-trophy"/>
                                <span className="d-none d-sm-inline"> Add Prizes</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('fourth', 'first', 'second', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="fourth1">
                                <span className="fa fa-sign-out"/>
                                <span className="d-none d-sm-inline"> Finish</span>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>

                        <div className="tab-pane active show" id="first">
                            <form id="accountForm" method="post" action="#" className="form-horizontal">
                                <div className="row">
                                    <div className="col-12">
                                      <div className="form-group row mb-3">
                                        <label className="col-md-3 col-form-label" htmlFor="gameName"> Game Name</label>
                                        <div className="col-md-9">
                                          <input id="gameName" name="gameName" type="text" className="form-control" value={this.state.gameName} onChange={this.handleChange} placeholder="12/11 vs MonStars" />
                                        </div>
                                      </div>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="startTime"> Set Start Time</label>
                                            <div className="col-md-9">
                                                <input id="startTime" name="startTime" type="datetime-local" className="form-control" value={this.state.startTime} onChange={this.handleChange} defaultValue={this.getCurrentTime()} />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="endTime"> Set End Time</label>
                                            <div className="col-md-9">
                                                <input id="endTime" name="endTime" type="datetime-local" className="form-control" value={this.state.endTime} onChange={this.handleChange} defaultValue={this.getCurrentTime()}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="tab-pane" id="second">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group" align="center">
                                        <label htmlFor="scrambleImage" style={{width:'100%'}}>Scramble Image (600px X 600px)</label>
                                        <Dropzone
                                            className="dropzone dz-clickable"
                                            accept="image/*"
                                            onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'scrambleImage')}}
                                            multiple={false}
                                            maxSize={2200000}
                                            style={{width:600}}>
                                            <div className="dz-message needsclick">
                                                <span className="fa fa-cloud-upload text-muted" style={{display: scrambleImage.preview ? 'none' : ''}}/>
                                                <h3 style={{display: scrambleImage.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                                                <img
                                                    style={{display: scrambleImage.preview ? '' : 'none'}}
                                                    src={scrambleImage.preview}
                                                    width="100px"
                                                    height="auto"
                                                    alt="Drop your image here"
                                                />
                                            </div>
                                        </Dropzone>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12" align="center">
                                    {splitImages.length > 0 &&
                                        <div style={{
                                            width: 220,
                                            minHeight: 220,
                                            outline: "1px black solid",
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            padding: 5
                                        }}>
                                            {
                                                splitImages.map(function(item, index){
                                                    if(index > 7){
                                                        return;
                                                    }
                                            return(
                                                    <div key={index} style={{width: 60, height: 60, margin: 5}}>
                                                    <img width="60px" height="60px" src={item.dataURI} alt=""/>
                                                    </div>)
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                      <div className="tab-pane" id="third">
                        <div className="form-horizontal">
                          <div className="row">
                            <div className="col-12">
                                <Accordion style={{margin:10}}>
                                    <AccordionItem className="accordian-header" title={`Main Prize`}>
                                        <div style={{height:'10px', width:'100%'}}/>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="main_prize"> Main Prize</label>
                                            <div className="col-md-9">
                                                <select className="form-control" name="main_prize" id="main_prize" value={this.state.main_prize} onChange={this.handleChange}>
                                                    <option/>
                                                    {
                                                        this.state.ticketList.map(function(item,index){
                                                            return(
                                                                <option value={item.key} key={index}>{item.rewardName}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="main_prize_amount"> Main Prize Amount</label>
                                            <div className="col-md-4">
                                                <input id="main_prize_amount" name="main_prize_amount" type="number" className="form-control" value={this.state.main_prize_amount} onChange={this.handleChange} placeholder="5" />
                                            </div>
                                            <center className="col-md-1 col-sm-12">
                                                <input id="main_prize_codes" name="main_prize_codes" type="checkbox" checked={this.state.main_prize_codes} onChange={this.handleChange} />
                                            </center>
                                            <label className="col-md-4 col-form-label" htmlFor="main_prize_codes">Add Codes</label>
                                        </div>
                                        <div className="form-group row mb-3" style={{display: this.state.main_prize_codes ? 'flex' : 'none' }}>
                                            <label htmlFor="main_prize_codesArray" className="col-md-3 col-form-label">Enter Codes (code1,code2)</label>
                                            <div className="col-md-9">
                                                <textarea value={this.state.main_prize_codesArray} className="form-control" name="main_prize_codesArray" onChange={this.handleChange} placeholder="code1,code2,code3"/>
                                            </div>
                                        </div>
                                    </AccordionItem>
                                    <AccordionItem className="accordian-header" title={`Other Winner Prize`}>
                                        <div style={{height:'10px', width:'100%'}}/>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="other_prize"> Other Prize</label>
                                            <div className="col-md-9">
                                                <select className="form-control" name="other_prize" id="other_prize" value={this.state.other_prize} onChange={this.handleChange}>
                                                    <option/>
                                                    {
                                                        this.state.ticketList.map(function(item,index){
                                                            return(
                                                                <option value={item.key} key={index}>{item.rewardName}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                          </div>
                        </div>
                      </div>

                        <div className="tab-pane fade" id="fourth">
                            <form id="otherForm" method="post" action="#" className="form-horizontal"/>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-center">
                                            <h2 className="mt-0">
                                                <i className="mdi mdi-check-all"/>
                                            </h2>
                                            <h3 className="mt-0">Finish</h3>

                                            <div className="row form-group">
                                              <div className="col-md-12">
                                                <p>You are all set to create a game!  Before clicking "Create Game" make sure all the settings are the way you want. Feel free to navigate back to edit anything you like!</p>
                                              </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                        </div>

                        <ul className="list-inline wizard mb-0">
                            <li className="previous list-inline-item" style={{display: this.state.wizardLocation === 'first' ? 'none' : '' }}><a href="#" className="btn btn-primary btn-lg previous-wizard-button" onClick={() => this.navButtonClicked('prev')}>Previous</a></li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.navButtonClicked('next')} style={{display: this.state.wizardLocation === 'fourth' ? 'none' : '' }}>Next</a></li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()} style={{display: this.state.wizardLocation === 'fourth' ? '' : 'none' }}>Create Game</a></li>
                        </ul>

                      </div>
                    </div>
                </div>
              </div>
          </Modal>
       </div>
      );
    }
}

export default SetUpGame;
