import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import {appDatabasePrimaryFunctions, base} from '../../base';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import AuthService from '../utils/AuthService';
import swal from "sweetalert2";

class AdminHome extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.state = {
          users: 0,
          loading: true,
          usersAnswers: [],
        };
    }

    downloadUsers(){
        let csv = 'Email,Name,Zip Code\n';
        const vm = this;
        vm.setState({
            loading:true
        });
        appDatabasePrimaryFunctions.ref('users').once('value', function(snapshot){
            snapshot.forEach(function (childSnapshot) {
                csv += childSnapshot.val().email + ",";
                csv += (childSnapshot.val().name || "") + ",";
                csv += (childSnapshot.val().zipCode || "");
                csv += "\n";
            });
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
            hiddenElement.target = '_blank';
            const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv";
            hiddenElement.download = "all_time_users_" + date_got;
            hiddenElement.click();
            vm.setState({
                loading:false
            })
        });
    }

    componentDidMount(){
        const vm = this;
        this.usersRef = appDatabasePrimaryFunctions.ref('users').on('value', function(snapshot){
            vm.setState({
                loading: false,
                users: snapshot.numChildren()
            })
        });
        this.idKeyRef = base.fetch(`id_key`, {
            context: this,
            then(data){
                this.setState({
                    id_key: data
                })
            }
        }).catch(e=>{
            console.log(e)
        });
        this.privateKeyRef = base.fetch(`private_key`, {
            context: this,
            then(data){
                vm.getPageViews(data);
            }
        }).catch(e=>{
            console.log(e)
        });
    }

    componentWillUnmount() {
        appDatabasePrimaryFunctions.ref('users').off('value', this.usersRef);
    }

    getPageViews(key){
        const viewObject = {"viewId": process.env.REACT_APP_GA_VIEW_ID, "private_key": key.replace(/\\n/g, '\n'),
            "client_email_beginning": process.env.REACT_APP_FIREBASE_PROJECT_ID};
        this.Auth.fetch('https://firebase-ga-service.herokuapp.com/getSessions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'IDToken': this.state.id_key
            },
            body: JSON.stringify(viewObject)
        }).then(response => {
            if(!response){
                swal({
                    title: 'Something went wrong!  Check your internet connection and try again!',
                    type: 'error',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
                return
            }
            if(response.message === "got_stats"){
                this.setState({
                    pageViews: response.page_views
                })
            } else {
                console.log("ERROR")
                console.log(response)
            }
            this.setState({
                loading: false
            })
        })
    }

    render() {
        const totalUsers = this.state.users || 0;
        const pageViews = this.state.pageViews || 0;
        const players = this.state.players || 0;
        const coupons_sent = this.state.coupons_sent || 0;
        const opens = this.state.opens || 0;
        const fan_side_link = process.env.REACT_APP_FAN_LINK || "";
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="top-button-styles">
                <button className="export-button-styles btn btn-primary btn-lg download-button" onClick={()=>this.downloadUsers()}><span className="fa fa-arrow-circle-down"/> Download Users</button>
              </div>
                <p className="admin-header-text" style={{marginTop:'10px'}}>Dashboard</p>
              <div className="admin-grid-container four-columns">
                  {/*<div className="card card-styles text-xs-center">*/}
                  {/*    <div className="card-body">*/}
                  {/*        <blockquote className="card-bodyquote">*/}
                  {/*            <p>Page Views</p>*/}
                  {/*            <footer className="value-text">{pageViews}</footer>*/}
                  {/*        </blockquote>*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                  {/*<div className="card card-styles text-xs-center">*/}
                  {/*    <div className="card-body">*/}
                  {/*        <blockquote className="card-bodyquote">*/}
                  {/*            <p>Players</p>*/}
                  {/*            <footer className="value-text">{players}</footer>*/}
                  {/*        </blockquote>*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                  {/*<div className="card card-styles text-xs-center">*/}
                  {/*    <div className="card-body">*/}
                  {/*        <blockquote className="card-bodyquote">*/}
                  {/*            <p>Coupons Sent</p>*/}
                  {/*            <footer className="value-text">{coupons_sent}</footer>*/}
                  {/*            <footer className="value-text">{opens}</footer>*/}
                  {/*        </blockquote>*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                <div className="card card-styles text-xs-center">
                    <div className="card-body">
                        <blockquote className="card-bodyquote">
                            <p>Accounts Made</p>
                            <footer className="value-text">{totalUsers}</footer>
                        </blockquote>
                    </div>
                </div>
              </div>
              <div className="row" style={{width:'100%'}}>
                <div className="col-md-12">
                  <p className="admin-header-text" style={{marginTop:'10px'}}>Game link: <span style={{fontSize:'20px'}}>{fan_side_link}</span></p>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default AdminHome
