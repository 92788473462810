import React, { Component } from 'react'
import '../../styles/css/SideMenu.css'
import '../../styles/css/AdminMain.css'
import '../../styles/css/TopBar.css'
import logoImage from '../../styles/images/sqwad_logo.png';

class TopBar extends Component {
    constructor(props) {
        super(props);
    }

    capitalize(str){
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    render() {
      // var teamLocation = window.location.pathname.split('/')[1] || ""
      var teamName = "SQWAD"
      // var homeLink = "/" + teamName + "/adminHome"
      // var scratcherGameLink = "/" + teamName + "/setupscratchergame"
      // var ticketsLink = "/" + teamName + "/setupscratchertickets"
      // var teamVariables = "/" + teamName + "/setupteamvariables"
      // var rulesAndRegs = "/" + teamName + "/setuprulesandregs"
      // var ticketEmail = "/" + teamName + "/setupticketemail"
      var teamLogo = logoImage
      // if(localStorage.getItem('topLeftLogo'+teamLocation) === "null" || !localStorage.getItem('topLeftLogo'+teamLocation)){
      //   teamLogo = logoImage
      // }
      teamName = this.capitalize(teamName);
        return (
          <div className="admin-topbar">
            <p className="topBarText">
              <img src={teamLogo} width="50px" height="auto" alt="Team" className="rounded-circle" style={{marginRight:'5px'}}/>
              {teamName}
            </p>
          </div>

        );
    }
}

export default TopBar
